<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="onClose"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				:model="formData"
				:rules="formRule"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="项目类型" prop="projectType">
							<el-radio-group v-model="formData.projectType" :disabled="projectTypeDisabledComp">
								<el-radio
									v-for="(item, index) in projectTypeOptions"
									:key="index"
									:label="item.code"
								>
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="维修项目名称" prop="name">
							<el-input
								v-model="formData.name"
								placeholder="请输入维修项目名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="上级项目名称" prop="parentId">
							<el-select
								v-model="formData.parentId"
								filterable
								placeholder="请选择上级项目名称"
								style="width: 100%"
								clearable
								@change="onParentChange"
							>
								<el-option
									v-for="(item, index) in parentTypeOptions"
									:key="item.id"
									:label="item.name"
									:value="item.id"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="排序" prop="sort">
							<el-input-number
								v-model="formData.sort"
								class="number-input-left short-box"
								placeholder="请输入排序"
								step-strictly
								:controls="false"
								:min="0"
								:step="1"
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="项目状态" prop="status">
							<el-radio-group v-model="formData.status" :disabled="isDisabled">
								<el-radio v-for="(item, index) in statusOptions" :key="index" :label="item.code">
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button :loading="isLoading" type="primary" :disabled="isDisabled" @click="onSubmit">
					保 存
				</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref, defineEmits, computed } from 'vue'
import { rules } from '../config'

const formInstance = getCurrentInstance()
const $message = inject('$message')
const $messageBox = inject('$messageBox')
const emit = defineEmits(['refresh'])

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const dialogTitle = ref('')

const formInitValue = {
	parentId: '',
	projectType: '',
	name: '',
	status: '',
	sort: '',
}

const formData = reactive({ ...formInitValue })
const formRule = rules
const parentTypeOptions = ref([])

const statusOptions = [
	{ name: '启用', code: 1 },
	{ name: '禁用', code: 0 },
]

// 1-维修，2-保养，3-易损件
const projectTypeOptions = [
	{ name: '维修', code: 1 },
	{ name: '保养', code: 2 },
	{ name: '易损件', code: 3 },
]

let formType = 'create'
let waitUpdateId = ''

const projectTypeDisabledComp = computed(() => {
	if (formData.parentId) {
		return true
	} else {
		return false
	}
})

function onParentChange(e) {
	console.log('检查到父级项目修改 ==>', e)
	const parentItem = parentTypeOptions.value.find(item => item.id === e)
	console.log('找到的父级项目 ==>', parentItem)
	if (parentItem) {
		formData.projectType = parentItem.projectType
	}
}

function initEnums() {
	Request({
		url: 'suf4-user-service/maintainingProject/select/1',
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				parentTypeOptions.value = res.data.filter(item => {
					if (formType === 'update') {
						return item.id !== waitUpdateId
					} else {
						return true
					}
				})
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function create() {
	formType = 'create'
	dialogTitle.value = '新增维修项目'
	initEnums()
	isShowDialog.value = true
}

function update(item) {
	formType = 'update'
	dialogTitle.value = '编辑维修项目'
	waitUpdateId = item.id
	getDetailById(item.id)
	Object.assign(formData, item)
	initEnums()
	isShowDialog.value = true
}

function getDetailById(id) {
	Request({
		url: `suf4-user-service/maintainingProject/getById/${id}`,
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('枚举获取成功 ==>', res.data)
				Object.assign(formData, res.data)
				if (res.data.parentId === '0' || !res.data.parentId) {
					formData.parentId = ''
				} else {
					formData.projectType = res.data.parentType
				}
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function deleteItem() {
	$messageBox
		.confirm('确定将删除该条数据？', '提示', {
			type: 'warning',
			confirmButtonText: '确定',
			cancelButtonText: '取消',
		})
		.then(() => {})
		.catch(() => {})
}

async function onSubmit() {
	try {
		isLoading.value = true
		const formValidRes = await formInstance.refs.ruleFormRef.validate()
		if (!formValidRes) {
			throw new Error('表单校验失败, 请检查!')
		}
		console.log('表单校验成功 ==>', formValidRes, formData)
		let requestUrl = ''
		const params = formData
		if (formType === 'create') {
			requestUrl = `suf4-user-service/maintainingProject/create`
			delete params.id
		} else {
			requestUrl = `suf4-user-service/maintainingProject/update`
			params.id = waitUpdateId
		}
		const updateRes = await Request({
			url: requestUrl,
			method: 'POST',
			data: params,
		})
		if (updateRes.code === 200) {
			$message.success(`${formType === 'create' ? '创建' : '更新'}成功!`)
			isLoading.value = false
			onClose()
			emit('refresh')
		} else {
			throw new Error(updateRes.msg)
		}
	} catch (e) {
		isLoading.value = false
		$message.error(e.message || '表单校验失败, 请检查')
	}
}

function onClose() {
	isShowDialog.value = false
	Object.assign(formData, formInitValue)
}

defineExpose({ create, update, deleteItem })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
