export const columns = [
	{
		prop: 'name',
		label: '项目名称',
	},
	{
		prop: 'projectType',
		label: '项目类型',
		formatter: function (row, column, cellValue, index) {
			// 1-维修，2-保养，3-易损件
			switch (cellValue) {
				case 1:
					return '维修'
				case 2:
					return '保养'
				case 3:
					return '易损件'
			}
		},
	},
	{
		prop: 'firstLevelName',
		label: '一级名称',
	},
	{
		prop: 'secondLevelName',
		label: '二级名称',
	},
	{
		prop: 'status',
		label: '项目状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 1 ? '启用' : '禁用'
		},
	},
	{
		prop: 'sort',
		label: '项目顺序',
	},
	/* {
		prop: 'rolePermissions',
		label: '联系人',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '个人' : '公司'
		},
	}, */
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '项目名称',
			attr: 'name',
			type: 'search',
			placeholder: '请输入项目名称',
			clearable: true,
		},
		{
			attr: 'firstLevelId',
			label: '一级名称',
			type: 'multi-select',
			placeholder: '请选择一级名称',
		},
		/* {
			attr: 'secondLevelId',
			label: '二级名称',
			type: 'multi-select',
			placeholder: '请选择二级名称',
		}, */
		{
			attr: 'status',
			label: '项目状态',
			type: 'select',
			placeholder: '请选择项目状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
	status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
	// parentId: [{ required: true, message: '请选择父级项目', trigger: 'blur' }],
	sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
	projectType: [{ required: true, message: '请选择维修项目类型', trigger: 'blur' }],
}
